import React from 'react';
import { withPreview } from 'gatsby-source-prismic';
import { graphql } from 'gatsby';

import SEO from '../components/globalComponents/SEO';
import HomeTextContent from '../components/HomeTextContent';

export const query = graphql`
  query homePageQuery {
    prismicHomePage {
      _previewable
      prismicId
      uid
      url
      data {
        homepage_title {
          text
        }
        homepage_paragraph {
          html
        }
      }
    }
  }
`;

const Home = props => {
  if (!props) return null;

  const dataProps = props;
  const homePageRes = dataProps.data.prismicHomePage.data;

  const previewHeroTitle = homePageRes.homepage_title.text;
  const previewheroRichText = homePageRes.homepage_paragraph.html;

  return (
    <main id="main">
      <SEO />
      <HomeTextContent
        previewHeroTitle={previewHeroTitle}
        previewheroRichText={previewheroRichText}
      />
    </main>
  );
};

export default withPreview(Home);
