/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import HTMLRenderer from 'react-html-renderer';

const MainTextContent = styled.section`
  h2 {
    margin-top: 24px;
    margin-bottom: 2rem;
    font-weight: 400;
  }
`;

const TopRightTextContent = props => {
  if (!props) return null;

  const data = useStaticQuery(graphql`
    query homePagePrimaryCopyQuery {
      prismicHomePage {
        _previewable
        data {
          homepage_title {
            text
          }
          homepage_paragraph {
            html
          }
        }
      }
    }
  `);

  const mainTextRes = data.prismicHomePage.data;

  // CONTENT
  const heroTitle = mainTextRes.homepage_title.text;
  const heroRichText = mainTextRes.homepage_paragraph.html;

  // PASS CONTENT AS PROPS TO PREVIEW
  const { previewHeroTitle, previewheroRichText } = props;

  return (
    <MainTextContent>
      <h1>{previewHeroTitle || heroTitle}</h1>
      <HTMLRenderer html={previewheroRichText || heroRichText} />
    </MainTextContent>
  );
};

export default TopRightTextContent;
